<template>
  <div
    class="widget-item button-widget"
    :id="widget.object.wid"
    :title="widget.object.captionText"
    :style="{
      ...style,
      ...(widget.object.imageAsButton ? style : buttonStyle),
    }"
    @click="openButtonModal"
  >
    <img
      v-if="widget.object.imageAsButton && widget.object.imageUrl"
      :style="imageStyle"
      :src="widget.object.imageUrl"
    />
    <span v-else-if="!widget.object.imageAsButton" class="button-text">
      {{ widget.object.text }}
    </span>
  </div>
</template>

<script>
  import templateWidget from '@/components/templates/mixins/templateWidget';

  import { simpleTypeMixin } from '@/helpers';
  import { buttonStyle } from '@/helpers/widgets/buttonWidget/buttonWidget.ts';
  import { OPEN_PLAYER_MODAL } from '@/store/actions/player';

  export default {
    name: 'ButtonWidget',

    mixins: [templateWidget, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
      },
    },

    data() {
      return {
        style: {
          position: 'absolute',
        },
      };
    },

    mounted() {
      this.renderWidget();
    },

    computed: {
      buttonStyle() {
        return buttonStyle(this.widget.object, this.widget.position.zIndex);
      },

      imageStyle() {
        return {
          objectFit: this.widget.object.imageMode,
          height: '100%',
          width: '100%',
        };
      },
    },

    methods: {
      renderWidget() {
        this.style = this.defaultStyling(this.widget);
      },

      openButtonModal() {
        const allowButtonClick = this.$store.state.player.allowPlayerModal;

        if (this.widget.object.contentLink && allowButtonClick) {
          this.$store.commit(OPEN_PLAYER_MODAL, this.widget.object);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-text {
    white-space: -moz-nowrap; /* Firefox */
    white-space: -o-nowrap; /* Opera */
    white-space: nowrap; /* Chrome */
    word-wrap: break-word; /* IE */

    font-weight: inherit;
    font-family: inherit;
  }

  .button-widget {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
