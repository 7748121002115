<template>
  <div :id="widget.object.item_id" class="widget-item" :style="style">
    <div v-if="widget.object.useLink" :style="{ height: '100%', width: '100%' }">
      <iframe
        v-if="widget.object.link && widget.object.link.length !== 0"
        class="iframe-container"
        :src="widget.object.link"
        :title="widget.object.link"
        scrolling="no"
      />
      <div v-else class="layer-link">
        <i class="material-icons-outlined align-middle">language</i>
        Website Link
      </div>
    </div>
  </div>
</template>

<script>
  import { duration } from 'moment';

  import RssViewer from '@/components/rssViewer/Index';
  import TableAppWebView from '@/components/tableApp/TableAppWebView';
  import { simpleTypeMixin } from '@/helpers';
  import templateWidget from '@/components/templates/mixins/templateWidget';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'LayerWidget',

    components: {
      RssViewer,
      TableAppWebView,
    },

    mixins: [simpleTypeMixin, templateWidget],

    props: {
      widget: {
        type: Object,
      },
    },

    data() {
      return {
        showLayer: true,
      };
    },

    mounted() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
      }

      if (
        this.widget.object.useLink &&
        !!this.widget.object.link &&
        this.widget.object.refreshTime &&
        this.widget.object.refreshTime > 0
      ) {
        const refreshInterval = duration(
          this.widget.object.refreshTime || 24,
          this.widget.object.timeUnit || 'hours',
        ).asMilliseconds();

        this.refreshTimer = setInterval(this.refreshLayer, refreshInterval);
      }
    },

    beforeDestroy() {
      if (this.refreshTimer) clearInterval(this.refreshTimer);
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return {
          ...style,
          overflow: 'hidden',
          backgroundColor: getRBGAColor(
            this.widget.object.backgroundColor,
            this.widget.object.opacity / 100,
          ),
        };
      },

      isPreview() {
        return this.$store.state.player.isPreviewPlayer;
      },
    },

    methods: {
      refreshLayer() {
        this.showLayer = false;
        const _this = this;

        setTimeout(function () {
          _this.showLayer = true;
        }, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-item {
    position: absolute;
    overflow: hidden;
  }

  .iframe-container {
    width: 100%;
    height: 100%;
    border: 0;
    pointer-events: none;
    overflow: hidden;
  }

  .layer-link {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $secondaryText;
    font-size: 32px;

    i {
      font-size: 64px;
    }
  }
</style>
