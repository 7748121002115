<template>
  <div v-if="!isPreview" class="audio-slide-preview" :style="audioStyle">
    <div class="button-layer">
      <div class="button-container">
        <i class="material-symbols-outlined align-middle">audio_file</i>
      </div>
    </div>
  </div>
  <audio
    v-else
    :ref="`playlist_audio_${itemId}`"
    muted
    loop
    controls
    :class="['playlist-slide-audio']"
    :style="audioStyle"
  >
    <source :src="getEncodedURL(baseUrl, itemUrl)" :type="itemType" />
    Your browser does not support the audio extension.
  </audio>
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import { mapState } from 'vuex';

  export default {
    name: 'AudioSlide',
    mixins: [simpleTypeMixin],

    props: {
      itemUrl: {
        type: String,
        default: null,
      },
      itemId: {
        type: String,
        default: null,
      },
      itemType: {
        type: String,
        default: null,
      },
      volume: {
        type: Number,
        default: 1,
      },
      mute: {
        type: Boolean,
        default: false,
      },
      imageMode: {
        type: String,
        default: 'cover',
      },
    },

    mounted() {
      const audioRef = this.$refs[`playlist_audio_${this.itemId}`];

      if (audioRef) {
        audioRef.volume = this.volume;
        audioRef.muted = this.mute;
        audioRef.play();
      }
    },

    computed: {
      ...mapState({
        isPreview: (state) => state.preview.isPreview,
      }),

      audioStyle() {
        return {
          objectFit: this.imageMode || 'cover',
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .audio-slide-preview {
    position: relative;
    background-color: $fillGrey;
    cursor: pointer;
    width: 100%;
    height: 100%;

    .button-layer {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      width: 100%;
      height: 100%;

      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;

        border-radius: 100%;
        background: rgba(255, 255, 255, 0.45);
        border: 2px solid rgba(255, 255, 255, 0.15);

        i {
          font-size: 32px;
          color: $primaryRed;
        }
      }
    }
  }
</style>
