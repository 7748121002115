<template>
  <div ref="templateContainer" v-if="tile" class="tile-main-container" :style="tileStyle">
    <template v-if="!isLoading">
      <TemplateLayer v-for="widget in widgets" :key="widget.assoc_id" :widget="widget" />
    </template>
    <div v-else class="loader">
      <div class="loader-background">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateLayer from '@/components/templates/viewer/TemplateLayer.vue';
  import Loader from '@/components/common/Loader.vue';

  import {
    apiGetGroupTileChildren,
    apiGetScreenTileChildren,
    apiGetTileChildren,
  } from '@/api/tiles';

  import { formatTileWidgets, hexToRgb } from '@/helpers/mixins';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'TileViewer',

    components: {
      TemplateLayer,
      Loader,
    },

    props: {
      tile: {
        type: Object,
        default: () => null,
      },

      zoom: {
        type: Number,
        default: 1,
      },

      playlistId: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        widgets: [],
        isLoading: false,
      };
    },

    mounted() {
      this.getWidgets();
    },

    watch: {
      tile(value) {
        if (value) {
          this.getWidgets();
        }
      },
    },

    computed: {
      tileStyle() {
        const tile = this.tile?.settings || {};

        return {
          backgroundColor: getRBGAColor(tile.backgroundColor, tile.opacity / 100),
          width: `100%`,
          height: `100%`,
          zoom: this.zoom,
          overflow: 'hidden',
        };
      },
    },

    methods: {
      async getWidgets() {
        try {
          const { screenId, groupId, layout } = this.$store.state.player;
          this.isLoading = true;
          const tileId = this.tile.template_id;
          this.widgets = [];

          let response;

          if (groupId) {
            response = await apiGetGroupTileChildren(
              tileId,
              groupId,
              this.playlistId,
              layout?.layout_id,
            );
          } else if (screenId) {
            response = await apiGetScreenTileChildren(tileId, screenId, this.playlistId);
          } else {
            response = await apiGetTileChildren(tileId);
          }

          const formatedWidgets = formatTileWidgets(response.data);

          this.widgets = formatedWidgets;
          this.isLoading = false;
        } catch (error) {
          console.log('getWidgets ~ error:', error);
          this.$toasted.global.general_error({
            message: 'Not able to load template preview',
          });
        }
      },
    },

    provide() {
      return {
        template: () => this.tile,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .tile-main-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    .loader-background {
      background-color: rgba(0, 0, 0, 0.25);

      border-radius: 10px;

      height: min(100px, 80%);
      width: min(100px, 80%);
    }
  }
</style>
