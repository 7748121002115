export enum Colors {
  LIGHT_BLUE = '#3c9cfc',
  DARK_BLUE = '#2f6eae',

  DARK_GREY = '#6c767d',
  GREY_300 = '#d9d9d9',
  LIGHT_GREY = '#f9f9f9',

  GREEN = '#23aa23',

  RED = '#d9534f',
  RED_LIGHT = '#f21818',

  WHITE_COLOR = '#ffffff',
  BACKGROUND_GREY = '#f7f8f7',
  BACKGROUND_GREY_2 = '#f3f3f3',
  BACKGROUND_GREY_3 = '#fafafa',
  HIGHLIGHT_GREY = '#f2f2f2',
  BORDER_GREY = '#e8e8e8',
  BORDER_GREY_2 = '#cfcfcf',

  LINE_GREY = '#f0f0f0',
  LINE_GREY2 = '#f5f5f5',
  GREEN_LIGHT = '#24b247',
  FILL_GREY = '#d9d9d9',

  PRIMARY_TEXT = '#151515',
  TABLE_TEXT = '#333332',
  SECONDARY_TEXT = '#6a6b6a',

  ICONS_BLACK = '#323232',

  PRIMARY_RED = '#e3003a',

  PRIMARY_YELLOW = '#feef93',
  SECONDARY_YELLOW = '#fff7bc',

  PRIMARY_DARK = '#333332',
  ORANGE_COLOR = '#ff9900',
}
