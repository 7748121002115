<template>
  <div class="widget-item tenats" :id="widget.object.wid" :style="style">
    <TenantsViewer :widgetData="widget.object" />
  </div>
</template>

<script>
  import TenantsViewer from '@/components/apps/tenants/TenantsViewer.vue';

  import templateWidget from '@/components/templates/mixins/templateWidget';
  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'TenantsWidget',

    components: { TenantsViewer },

    mixins: [simpleTypeMixin, templateWidget],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        return {
          ...this.defaultStyling(this.widget),
          overflow: 'hidden',
          backgroundColor: getRBGAColor(
            this.widget.object.backgroundColor,
            this.widget.object.opacity / 100,
          ),
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-item {
    position: absolute;
    overflow: hidden;
  }

  .tenant-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
  }
</style>
