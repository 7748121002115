<template>
  <div class="web-player-v2">
    <div class="initializing-overlay text-center pt-5" v-if="isLoadingPlayer">
      <h1>Initializing player. Please wait...</h1>
    </div>

    <div v-else-if="unauthorized" class="text-center pt-5">
      <div>
        <h1>Unable to connect this screen to the playlist you are trying to load.</h1>
        <div>
          <p>Detach the already attached screen to this playlist and reload this page.</p>

          <button @click.prevent="handleLinkReload" class="btn btn-warning">Reload</button>
        </div>
      </div>
    </div>

    <Layout v-else :layout="layout" :playlists="playlists" />
    <div
      class="borders"
      :style="getSelectedLayerStyles()"
      v-if="selectedLayerId && !isLoadingPlayer"
    ></div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Layout from '@/components/player/Layout.vue';
  import { PLAYER_LOAD_LAYOUT, SET_PLAYER_DEMO } from '@/store/actions/player';

  export default {
    name: 'player',

    components: {
      Layout,
    },

    props: {
      layout_id: {
        type: String,
        default: null,
      },

      screenId: {
        type: String | Number,
        default: null,
      },

      groupId: {
        type: String | Number,
        default: null,
      },

      isPreview: {
        type: Boolean,
        default: false,
      },

      highlight: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isLoadingPlayer: false,
      };
    },

    computed: {
      ...mapState({
        hasError: (state) => state.player.loadingLayoutError,
        layout: (state) => state.player.layout,
        playlists: (state) => state.player.playlists,
        selectedLayerId: (state) =>
          state.player.selectedWidget
            ? state.player.selectedWidget.object.item_id ||
              state.player.selectedWidget.object.playlist_id ||
              state.player.selectedWidget.object.template_id
            : null,
        selectedLayer: (state) => state.player.selectedWidget,
      }),

      unauthorized() {
        return !this.$store.getters.isAuthenticated && this.hasError;
      },
    },

    methods: {
      async loadLayout() {
        this.isLoadingPlayer = true;

        await this.$store.dispatch(PLAYER_LOAD_LAYOUT, {
          layoutId: this.layout_id || this.$route.params.layout_id,
          screenId: this.screenId || this.$route.params.screenId,
          groupId: this.groupId || this.$route.params.groupId,
          loadChildren: true,
          resetSelectedWidget: !this.highlight,
        });

        this.isLoadingPlayer = false;
      },

      handleLinkReload() {
        this.reloadWithCacheBusted();
      },

      reloadWithCacheBusted() {
        const port = window.location.port.trim() ? `:${window.location.port}` : '';
        const cacheBust = `?cacheBust=${Date.now()}`;

        window.location = `${window.location.protocol}//${window.location.hostname}${port}${cacheBust}${window.location.hash}`;
      },

      getSelectedLayerStyles() {
        const selectedLayer = this.$store.state.player.selectedWidget;

        if (!selectedLayer || !this.layout) {
          return {};
        }

        const position =
          selectedLayer.position[this.layout.settings.isHorizontal ? 'horizontal' : 'vertical'];
        const { usePixels } = position;

        const { height } = this.$store.getters.getPlayerResolution;
        const mainAxis = this.layout.settings.isHorizontal ? 'height' : 'width';

        const screenHeight = this.$store.state.player.screenSize[mainAxis];

        const zoom =
          screenHeight < height && this.$store.state.player.isPreviewPlayer
            ? screenHeight / height
            : 1;

        const styles = {
          left: usePixels ? `${position.xPixels}px` : `${position.x}%`,
          top: usePixels ? `${position.yPixels}px` : `${position.y}%`,
          height: usePixels ? `${position.heightPixels}px` : `${position.height}%`,
          width: usePixels ? `${position.widthPixels}px` : `${position.width}%`,
          zoom,
        };

        return styles;
      },
    },

    created() {
      this.loadLayout();
    },

    mounted() {
      document.body.classList.add('web-player-body-v2');

      this.$store.commit(SET_PLAYER_DEMO, this.isPreview);
    },

    beforeDestroy() {
      document.body.classList.remove('web-player-body-v2');
    },
  };
</script>

<style lang="scss">
  body.web-player-body-v2 {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    #mainAppWrapper {
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
  }
</style>

<style lang="scss" scoped>
  .web-player-v2 {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
    overflow: hidden;

    ::v-deep .widget-item {
      position: absolute;
    }

    .borders {
      position: absolute;
      border: 4px solid $primaryRed;
      box-sizing: border-box;
      z-index: 2000;
    }
  }

  pre {
    font-size: 1rem;
    height: 100%;
    overflow: auto;
    background-color: #000;
    color: #fff;
  }
</style>
