import { SET_PREVIEW_MUTATION, SET_PREVIEW } from '@/store/actions/preview';

export interface PreviewState {
  isPreview: boolean;
}

const initialState: PreviewState = { isPreview: false };

export default {
  state: initialState,
  mutations: {
    [SET_PREVIEW_MUTATION]: (state: PreviewState, isPreview: boolean) => {
      state.isPreview = isPreview;
    },
  },
  actions: {
    [SET_PREVIEW]: ({ commit }, isPreview: boolean) => {
      commit(SET_PREVIEW_MUTATION, isPreview);
    },
  },
  getters: {},
};
