import Vue from 'vue';
import _ from 'lodash';

import {
  GET_TEMPLATES,
  GET_TEMPLATES_FOLDER,
  LOAD_TEMPLATE,
  LOAD_TEMPLATE_CHILDREN,
  LOAD_TILE,
  LOAD_TILE_CHILDREN,
  SET_TEMPLATE_CHILDREN,
  SET_TEMPLATES,
  RESET_TEMPLATE,
  TEMPLATES_FOLDER_REQUEST,
  TEMPLATES_FOLDER_REQUEST_ERROR,
  TEMPLATE_FOLDER_SEARCH_REQUEST,
  TEMPLATE_FOLDER_CREATE,
  TEMPLATE_FOLDER_DELETE,
  SET_SAVING_TEMPLATE,
  SET_SAVE_TEMPLATE,

  // Widgets
  CREATE_TEMPLATE_LAYER,
  UPDATE_TEMPLATE_WIDGET_THUMBNAIL,
  CREATE_TEMPLATE_COMMON_WIDGET,
  TEMPLATE_DESIGNER_ADD_WIDGET,
  ATTACH_TEMPLATE_CHILD,
  REPLACE_TEMPLATE_DUMMMY_CHILD,
  TEMPLATE_REPLACE_CHILD_ITEM,
  TEMPLATE_REMOVE_MODIFIED_WIDGET,
  SET_SAVE_Z_INDEXES, // Fixing zindexes previous to save changes button

  // internal to editor
  TEMPLATE_DESIGNER_TO_SETTING_TAB,
  TEMPLATE_DESIGNER_SET_TEMPLATE,
  TEMPLATE_DESIGNER_TOGGLE_GRID,
  TEMPLATE_DESIGNER_TOGGLE_SNAP,

  // shared
  TEMPLATE_DESIGNER_DELETE_WIDGET,
  TEMPLATE_DESIGNER_DESELECT_WIDGET,
  UPDATE_TEMPLATE_WIDGET_STATE,
  UPDATE_TILE_WIDGET,
  TEMPLATES_REMOVE_DELETED_CHILDREN,
  TEMPLATES_ADD_REPLACED_WIDGET,
  TEMPLATES_DELETE_REPLACED_WIDGET,

  // designer to editor
  TEMPLATE_DESIGNER_SELECT_WIDGET,
  TEMPLATE_DESIGNER_SET_ZINDEX,
  SET_REFRESHING_TEMPLATE_STATE,
  SET_GUIDE_LINES,
  APPEND_TEMPLATES,
  SET_TEMPLATES_FOLDER,
  TEMPLATE_DESIGNER_DUPLICATE_WIDGET,
} from '@/store/actions/templateDesigner';

import { WIDGET_TYPES } from '@/models/layoutDesigner';
import { generateThumbnailDataUrl } from '@/models/layoutDesigner/konva';

import {
  apiAttachTemplateChild,
  apiGetTemplate,
  apiGetTemplateChildren,
  apiGetTemplates,
  apiGetTemplatesFolder,
  apiCreateFolder,
  apiDeleteFolder,
} from '@/api/templates';
import {
  apiGetGroupTileChildren,
  apiGetScreenTileChildren,
  apiGetTileChildren,
  apiGetTile,
} from '@/api/tiles';
import { apiCreateAppWidget, apiCreateLayerWidget } from '@/api/layouts';

import { CONTENT_TYPE_FOLDER } from '@/constant/const';
import { REFRESH_LAYOUT_STATUS } from '@/config/constants';

import {
  getFoldersFromNestedArray,
  arrayToObjectWithIdsAsKeys,
  getWidgetId,
  getWidgetItemType,
} from '@/helpers/utils';
import { restrictToBounds } from '@/helpers/draggable';
import { formatTileWidgets } from '@/helpers/mixins';
import { generateWidgetId } from '@/models/layoutDesigner';

const state = {
  widgets: {},
  deletedWidgets: {},
  modifiedWidgets: {},
  replacedWidgets: {},

  selectedWidget: null,
  template: null,
  templates: [],
  templatesFolder: [],

  refreshingTemplateStatus: REFRESH_LAYOUT_STATUS.HOLD,

  vLine: [],
  hLine: [],

  isWidgetSettingTab: true,
  showTemplateGrid: false,
  isSnapGridActive: true,
  templateNeedSaving: false,
  isSavingTemplate: false,
  saveTemplate: false,
  fixZIndexes: false,

  requestStatus: '',
  requestError: '',
  folderSearch: '',

  layoutId: null,
  screenId: null,
  groupId: null,
  playlistId: null,
};

const getters = {
  getWidgetsByZIndexInTemplates: (state) => {
    return Object.values(state.widgets).sort(
      (widget1, widget2) => widget1.position.zIndex - widget2.position.zIndex,
    );
  },

  hasAnyGroupOverride: (state) => {
    return Object.values(state.widgets).some((widget, index) => {
      return !!widget.object.override?.screen_group;
    });
  },

  getTemplateDimensions: (state) => {
    let ratio = 1;
    const { height = 200, width = 200 } = state.template?.settings || {};
    let layoutHeight = height;
    let layoutWidth = width;

    if (layoutWidth > 800) {
      ratio = 800 / layoutWidth;

      layoutHeight = layoutHeight * ratio;
    }

    layoutWidth = restrictToBounds(layoutWidth, 1, 800);

    return { height, width, ratio, layoutHeight, layoutWidth };
  },

  getFolderDetails: (state) => {
    return state.templatesFolder.filter((item) => {
      let validFolder = item.content_type === CONTENT_TYPE_FOLDER;
      if (validFolder && state.folderSearch) {
        validFolder = item?.name?.toLowerCase()?.includes(state.folderSearch);
      }
      return validFolder;
    });
  },

  getAllTemplateFolders: (state) => {
    return getFoldersFromNestedArray(state.templatesFolder);
  },
};

const actions = {
  [GET_TEMPLATES]: async ({ commit }, { query = {}, filterByRole = false }) => {
    try {
      const response = await apiGetTemplates(query, filterByRole);

      const items = response.data.items || response.data;

      const templateList = _.orderBy(items, 'created', ['desc']);
      const templates = templateList.map((template) => ({
        ...template,
        image: template.settings.image,
      }));

      if (query.page && query.page > 1) {
        commit(APPEND_TEMPLATES, { templates });
      } else {
        commit(SET_TEMPLATES, { templates });
      }

      return templates;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [GET_TEMPLATES_FOLDER]: async ({ commit }, { query = {}, filterByRole = false }) => {
    try {
      commit(TEMPLATES_FOLDER_REQUEST);
      const response = await apiGetTemplatesFolder(query, filterByRole);
      commit(SET_TEMPLATES_FOLDER, { templates: processFolderData(response.data?.contents ?? []) });
    } catch (error) {
      console.log('error: ', error);
      commit(TEMPLATES_FOLDER_REQUEST_ERROR, error);
      return false;
    }
  },

  [TEMPLATE_FOLDER_SEARCH_REQUEST]: ({ commit }, folderName) => {
    commit(TEMPLATE_FOLDER_SEARCH_REQUEST, folderName);
  },

  [TEMPLATE_FOLDER_CREATE]: ({ commit }, { parent_id, folderName }) => {
    return apiCreateFolder(parent_id, folderName);
  },

  [TEMPLATE_FOLDER_DELETE]: ({ commit }, { id }) => {
    return apiDeleteFolder(id);
  },

  [LOAD_TEMPLATE]: async ({ commit, dispatch }, { templateId }) => {
    try {
      const response = await apiGetTemplate(templateId);
      const template = response.data;

      commit(TEMPLATE_DESIGNER_SET_TEMPLATE, { template, saveTemplate: false });

      await dispatch(LOAD_TEMPLATE_CHILDREN, templateId);

      return template;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [LOAD_TEMPLATE_CHILDREN]: async ({ commit }, templateId) => {
    try {
      const response = await apiGetTemplateChildren(templateId);

      const formatedWidgets = response.data.map((widget) => {
        const isAnApp = widget.object.item_type?.includes('app');

        let itemType = getWidgetItemType(widget);

        if (isAnApp && widget.object.config?.override) delete widget.object.config.override;

        const layouObject = isAnApp ? { ...widget.object, ...widget.object.config } : widget.object;

        return {
          ...widget,
          assoc_id: widget.assoc_id,
          object: { ...layouObject, type: itemType, wid: generateWidgetId(), config: undefined },
          itemType,
        };
      });

      let updateZIndexes = false;

      // Fixing z-indexes
      formatedWidgets
        .sort((widget1, widget2) => widget1.position.zIndex - widget2.position.zIndex)
        .forEach((widget, index) => {
          if (widget.position.zIndex !== index) {
            updateZIndexes = true;
          }
        });

      commit(SET_SAVE_Z_INDEXES, updateZIndexes);
      commit(SET_TEMPLATE_CHILDREN, arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'));

      return formatedWidgets;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [LOAD_TILE]: async ({ commit, dispatch, state }, { tileId }) => {
    try {
      const response = await apiGetTile(tileId);
      const tile = {
        ...response.data.local_template,
        playlistItemId: response.data.playlist_item.item_id,
      };

      commit(TEMPLATE_DESIGNER_SET_TEMPLATE, { template: tile, saveTemplate: false });

      await dispatch(LOAD_TILE_CHILDREN, tileId);

      return tile;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [LOAD_TILE_CHILDREN]: async ({ commit, state }, tileId) => {
    try {
      const { groupId, screenId, playlistId, layoutId } = state;

      let response;

      if (groupId) {
        response = await apiGetGroupTileChildren(tileId, groupId, playlistId, layoutId);
      } else if (screenId) {
        response = await apiGetScreenTileChildren(tileId, screenId, playlistId);
      } else {
        response = await apiGetTileChildren(tileId);
      }

      const formatedWidgets = formatTileWidgets(response.data);

      commit(SET_TEMPLATE_CHILDREN, arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'));

      return formatedWidgets;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [CREATE_TEMPLATE_LAYER]: async ({ dispatch }, { templateId, widget }) => {
    try {
      const response = await apiCreateLayerWidget(widget);

      const widgetData = {
        type: WIDGET_TYPES.LAYOUT_ITEM,
        item_id: response.data.item_id,
        position: widget.position,
      };

      const result = await dispatch(ATTACH_TEMPLATE_CHILD, {
        templateId,
        widgetData,
        widget: { ...widget, ...response.data, position: undefined },
      });

      return result.data;
    } catch (error) {
      console.log('error: ', error);
    }
  },

  [CREATE_TEMPLATE_COMMON_WIDGET]: async (
    { dispatch },
    { templateId, widget, widgetData: appData, position },
  ) => {
    try {
      const response = await apiCreateAppWidget(appData);

      const widgetData = {
        type: WIDGET_TYPES.LAYOUT_ITEM,
        item_id: response.data.item_id,
        position,
      };

      const result = await dispatch(ATTACH_TEMPLATE_CHILD, {
        templateId,
        widgetData,
        widget: { ...widget, ...response.data },
      });

      return result.data;
    } catch (error) {
      console.log('error: ', error);
    }
  },

  [UPDATE_TEMPLATE_WIDGET_THUMBNAIL]: async ({ commit, state, getters }) => {
    try {
      const template = state.template;
      const thumbnailWidth = state.template.settings.width;
      const thumbnailHeight = state.template.settings.height;
      const widgetsForThumbnail = getters.getWidgetsByZIndexInTemplates.map((widget) => ({
        ...widget.position,
      }));

      const newTemplateThumbnail = generateThumbnailDataUrl(
        widgetsForThumbnail,
        thumbnailWidth,
        thumbnailHeight,
        true,
      );

      const updatedTemplate = {
        ...template,
        settings: {
          ...template.settings,
          image: newTemplateThumbnail,
        },
      };

      commit(TEMPLATE_DESIGNER_SET_TEMPLATE, { template: updatedTemplate, saveTemplate: false });

      return newTemplateThumbnail;
    } catch (error) {
      console.log('error: ', error);
    }
  },

  [ATTACH_TEMPLATE_CHILD]: async ({ commit }, { templateId, widgetData, widget }) => {
    try {
      const response = await apiAttachTemplateChild({ templateId, widgetData });

      return response.data;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [TEMPLATE_REPLACE_CHILD_ITEM]: async ({ commit }, { widget, newItemId }) => {
    commit(UPDATE_TEMPLATE_WIDGET_STATE, { widget });

    if (widget.assoc_id) {
      commit(TEMPLATES_ADD_REPLACED_WIDGET, { widget, newItemId });
    }
  },
};

const mutations = {
  [RESET_TEMPLATE]: (
    state,
    { layoutId = null, screenId = null, groupId = null, playlistId = null },
  ) => {
    state.template = null;
    state.widgets = {};
    state.deletedWidgets = {};
    state.modifiedWidgets = {};
    state.replacedWidgets = {};

    state.selectedWidget = null;
    state.saveTemplate = false;
    state.isSavingTemplate = false;

    state.layoutId = layoutId;
    state.screenId = screenId;
    state.groupId = groupId;
    state.playlistId = playlistId;
  },

  [SET_TEMPLATE_CHILDREN]: (state, widgets) => {
    Vue.set(state, 'widgets', widgets);
  },

  [SET_SAVE_Z_INDEXES]: (state, status = true) => {
    Vue.set(state, 'fixZIndexes', status);
  },

  // internal to editor
  [TEMPLATE_DESIGNER_TO_SETTING_TAB]: (state, yes) => {
    state.isWidgetSettingTab = yes;
  },

  [TEMPLATE_DESIGNER_SET_TEMPLATE](state, { template, saveTemplate = true }) {
    Vue.set(state, 'template', { ...template });

    if (saveTemplate) this.commit(SET_SAVE_TEMPLATE);
  },

  [SET_TEMPLATES]: (state, { templates = [] }) => {
    Vue.set(state, 'templates', templates);
  },

  [SET_TEMPLATES_FOLDER]: (state, { templates = [] }) => {
    Vue.set(state, 'templatesFolder', templates);
    state.requestStatus = 'success';
  },

  [TEMPLATE_FOLDER_SEARCH_REQUEST]: (_, folderName = '') => {
    state.folderSearch = folderName.toLowerCase();
  },

  [APPEND_TEMPLATES]: (state, { templates }) => {
    state.templates = [...state.templates, ...templates];
  },

  [TEMPLATE_DESIGNER_TOGGLE_GRID]: (state, value = false) => {
    state.showTemplateGrid = value;
  },

  [TEMPLATE_DESIGNER_TOGGLE_SNAP]: (state, value = false) => {
    state.isSnapGridActive = value;
  },

  // shared between editor and designer
  [TEMPLATE_DESIGNER_ADD_WIDGET](state, widget) {
    const widgetId = getWidgetId(widget);

    Vue.set(state.widgets, widgetId, widget);

    if (!widget.assoc_id) Vue.set(state.modifiedWidgets, widgetId, 'new');

    this.commit(TEMPLATE_DESIGNER_DESELECT_WIDGET);
    this.commit(SET_SAVE_TEMPLATE);
  },

  [TEMPLATE_DESIGNER_DUPLICATE_WIDGET](state, widget) {
    const widgetId = getWidgetId(widget);

    Vue.set(state.widgets, widgetId, widget);

    if (!widget.assoc_id) Vue.set(state.modifiedWidgets, widgetId, 'new');

    this.commit(TEMPLATE_DESIGNER_SELECT_WIDGET, widget);
    this.commit(SET_SAVE_TEMPLATE);
  },

  [TEMPLATE_DESIGNER_DELETE_WIDGET](state, { widget, saveOnDeletedWidgets = false }) {
    const widgetId = getWidgetId(widget);

    if (saveOnDeletedWidgets) {
      Vue.set(state.deletedWidgets, widgetId, widget);
      Vue.set(state.modifiedWidgets, widgetId, 'deleted');
    } else {
      Vue.delete(state.modifiedWidgets, widgetId);
    }

    Vue.delete(state.widgets, widgetId);

    this.commit(TEMPLATE_DESIGNER_DESELECT_WIDGET);
    this.commit(SET_SAVE_TEMPLATE);
  },

  [TEMPLATES_REMOVE_DELETED_CHILDREN](state, widgetId) {
    Vue.delete(state.deletedWidgets, widgetId);
    Vue.delete(state.modifiedWidgets, widgetId);
  },

  [TEMPLATES_ADD_REPLACED_WIDGET](state, { widget, newItemId }) {
    Vue.set(state.replacedWidgets, widget.assoc_id, newItemId);
  },

  [TEMPLATES_DELETE_REPLACED_WIDGET](state, widgetId) {
    Vue.delete(state.replacedWidgets, widgetId);
  },

  [REPLACE_TEMPLATE_DUMMMY_CHILD](state, { widget }) {
    const widgetId = state.widgets[widget.object.wid];

    if (widgetId) {
      Vue.delete(state.widgets, widget.object.wid);
      Vue.delete(state.modifiedWidgets, widget.object.wid);
    }

    this.commit(TEMPLATE_DESIGNER_DESELECT_WIDGET);
    this.commit(TEMPLATE_DESIGNER_ADD_WIDGET, widget);
  },

  [TEMPLATE_REMOVE_MODIFIED_WIDGET](state, widgetId) {
    Vue.delete(state.modifiedWidgets, widgetId);
  },

  [UPDATE_TEMPLATE_WIDGET_STATE](state, { widget, selectWidget = true }) {
    const newWidget = { ...widget };
    const widgetId = getWidgetId(widget);
    const modifiedWidget = state.modifiedWidgets[widgetId];

    if (state.widgets[widgetId]) {
      Vue.set(state.widgets, widgetId, newWidget);
      Vue.set(state.modifiedWidgets, widgetId, modifiedWidget === 'new' ? 'new' : 'modified');
    }

    if (selectWidget) this.commit(TEMPLATE_DESIGNER_SELECT_WIDGET, newWidget);

    this.commit(SET_SAVE_TEMPLATE);
  },

  [UPDATE_TILE_WIDGET](state, { widget }) {
    const widgetId = getWidgetId(widget);

    Vue.set(state.widgets, widgetId, widget);
    Vue.delete(state.modifiedWidgets, widgetId);
  },

  // designer to editor
  [TEMPLATE_DESIGNER_SET_ZINDEX](state, { widgetsToUpdate, widgetToSelect, selectWidget = false }) {
    widgetsToUpdate.forEach((widget) => {
      this.commit(UPDATE_TEMPLATE_WIDGET_STATE, { widget, selectWidget: false });
    });

    if (selectWidget) this.commit(TEMPLATE_DESIGNER_SELECT_WIDGET, widgetToSelect);
  },

  [TEMPLATE_DESIGNER_DESELECT_WIDGET]: (state) => {
    state.selectedWidget = null;
  },

  [TEMPLATE_DESIGNER_SELECT_WIDGET]: (state, widget) => {
    state.selectedWidget = { ...widget };

    state.isWidgetSettingTab = true;
  },

  [SET_REFRESHING_TEMPLATE_STATE]: (state, value = REFRESH_LAYOUT_STATUS.STAND_BY) => {
    state.refreshingTemplateStatus = value;
  },

  [SET_GUIDE_LINES]: (state, lines) => {
    state.vLine = lines.vLine;
    state.hLine = lines.hLine;
  },

  [TEMPLATES_FOLDER_REQUEST]: (state) => {
    state.requestStatus = 'loading';
  },

  [TEMPLATES_FOLDER_REQUEST_ERROR]: (state, _, error) => {
    state.requestError = String(error);
    state.requestStatus = 'error';
  },

  [SET_SAVE_TEMPLATE](state, value = true) {
    state.saveTemplate = value;
  },

  [SET_SAVING_TEMPLATE](state, value = true) {
    state.isSavingTemplate = value;
  },
};

export default {
  getters,
  state,
  actions,
  mutations,
};

const folderImg = require('@/assets/img/content/folder.png');
const processFolderData = (data) => {
  return data
    .map((el) => {
      const element = el;
      if (el.content_type === CONTENT_TYPE_FOLDER) {
        element.img = folderImg;
        element.contents = processFolderData(element.contents);
      } else {
        element.img = element.settings.image;
      }
      element.item_type = element.content_type;

      return element;
    })
    .filter((item) => !!item.item_type || !!item.content_type);
};
