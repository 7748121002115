import { render, staticRenderFns } from "./RichTextWidget.vue?vue&type=template&id=b7edcdd6&scoped=true"
import script from "./RichTextWidget.vue?vue&type=script&lang=js"
export * from "./RichTextWidget.vue?vue&type=script&lang=js"
import style0 from "./RichTextWidget.vue?vue&type=style&index=0&id=b7edcdd6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7edcdd6",
  null
  
)

export default component.exports