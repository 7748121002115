var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('Loader'):(_vm.currentSlide && _vm.filteredSlides.length > 0)?_c('div',{staticClass:"playlist-player"},[_vm._l((_vm.filteredSlides),function(slide,index){return _c('div',{key:`${slide.item_id}-${_vm.playlist.playlist_id}-${index}`,staticClass:"playlist-slide",class:{
      [_vm.slideClassIn]: _vm.displaySlide(slide),
      [_vm.slideClassOut]: !_vm.displaySlide(slide),
    }},[_c('PlaylistSlide',{attrs:{"slide":{ ...slide, imageMode: _vm.playlist.imageMode || 'cover' },"show":slide.item_id === _vm.currentSlide.item_id,"trafiklabZoom":_vm.trafiklabZoom,"playlist":_vm.playlist}})],1)}),((_vm.videoSlides.length > 0 && _vm.slideVideo1) || _vm.showVideoPreview)?_c('video',{ref:`video-${_vm.slideVideo1 ? _vm.slideVideo1.item_id : _vm.videoSlides[0].item_id}`,staticClass:"playlist-slide-video",class:{
      [_vm.slideClassIn]: _vm.showVideo1,
      [_vm.slideClassOut]: !_vm.showVideo1,
    },style:({
      objectFit: _vm.playlist?.imageMode || 'cover',
      visibility:
        _vm.showVideoPreview || _vm.currentSlide?.item_id === _vm.slideVideo1?.item_id ? 'visible' : 'hidden',
    }),attrs:{"loop":"","muted":"","src":_vm.getEncodedURL(_vm.baseUrl, (!_vm.showVideoPreview ? _vm.slideVideo1 : _vm.currentSlide)?.item_url)},domProps:{"muted":true},on:{"play":_vm.playingVideo1}}):_vm._e(),(_vm.videoSlides.length > 1 && _vm.slideVideo2)?_c('video',{ref:`video-${_vm.slideVideo2?.item_id}`,staticClass:"playlist-slide-video",class:{
      [_vm.slideClassIn]: _vm.showVideo2,
      [_vm.slideClassOut]: !_vm.showVideo2,
    },style:({
      objectFit: _vm.playlist?.imageMode || 'cover',
      visibility: _vm.currentSlide?.item_id === _vm.slideVideo2.item_id ? 'visible' : 'hidden',
    }),attrs:{"loop":"","muted":"","src":_vm.getEncodedURL(_vm.baseUrl, _vm.slideVideo2.item_url)},domProps:{"muted":true},on:{"play":_vm.playingVideo2}}):_vm._e()],2):(_vm.isPlaylistPreview || _vm.isPreview)?_c('div',{staticClass:"playlist-background"}):_c('div',{staticClass:"playlist-empty-state"},[_c('div',{staticClass:"playlist-name-section"},[_c('BaseText',{staticClass:"playlist-name-text",attrs:{"variant":"h5","color":"#e3003a"}},[_vm._v(" "+_vm._s(_vm.playlist.name)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }