import axios from 'axios';
import config from '../config';
import { FileUpload, FileUploadStatus } from '@/types/api/content';

axios.defaults.headers.common.Authorization = localStorage.getItem('user-session_token');

export const apiGetRootContent = () =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/contents/folders?remove_old_apps=true`;

    axios
      .get(apiUrl)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export async function apiUploadFile(
  name: string,
  file: File,
  folderId: number | null,
  onUploadProgress?: (progressEvent: any) => void,
) {
  const formData = new FormData();

  formData.append('name', name);
  formData.append('upload', file);

  if (folderId) {
    formData.append('folder', folderId.toString());
  }

  const response = await axios.post<FileUpload>(
    `${config.apiUrl}userapi/contents/items`,
    formData,
    {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        if (onUploadProgress) {
          onUploadProgress(event);
        }
      },
    },
  );

  return response.data;
}

export async function apiGetFileUploadStatus(fileUploadId: string) {
  const response = await axios.get<{ status: FileUploadStatus }>(
    `${config.apiUrl}userapi/file-upload-status`,
    { params: { fileUploadId: fileUploadId } },
  );

  return response.data;
}

export const apiDeleteFile = (fileId, forceHide = false) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/contents/items`;

    axios
      .delete(apiUrl, { params: { item_id: fileId, hide: forceHide } })
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiDeleteFiles = (fileIds, forceHide = false) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/contents/items`;

    axios
      .delete(apiUrl, { params: { item_id: fileIds, hide: forceHide } })
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export async function apiUploadPDF(
  file: File,
  images,
  folderId: number | null,
  onUploadProgress?: (progressEvent: any) => void,
) {
  const formData = new FormData();
  formData.append('pdfFile', file);
  images.forEach((image) => {
    formData.append('thumbs', image);
  });

  if (folderId) formData.append('folder', folderId.toString());

  const response = await axios.post(`${config.apiUrl}userapi/pdf`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (event) => {
      if (onUploadProgress) {
        onUploadProgress(event);
      }
    },
  });

  return response.data;
}

export const apiDeletePdf = (pdfId) => {
  const apiUrl = `${config.apiUrl}userapi/media?prefix=pdf_file&app_id=${pdfId}`;

  return axios.delete(apiUrl);
};

export const apiMoveFile = (itemId, folderId) => {
  const apiUrl = `${config.apiUrl}userapi/contents/items/${itemId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(apiUrl, { folder: folderId })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};

export const apiMultiMoveFiles = async (fileIds, folderId) => {
  const apiUrl = `${config.apiUrl}userapi/contents/items`;
  return axios.patch(apiUrl, { folder: folderId, item_ids: fileIds });
};

export async function apiCreateFolder(name: string, parentFolder: number | null) {
  const response = await axios.post(`${config.apiUrl}userapi/contents/folders`, {
    name: name,
    parent_folder: parentFolder,
  });

  return response.data;
}

export const apiDeleteFolder = (folderId) => {
  const apiUrl = `${config.apiUrl}userapi/contents/folders/${folderId}`;

  return axios.delete(apiUrl);
};

export const apiGetContentFolder = (folderId) => {
  const endpoint = `${config.apiUrl}userapi/contents/folders/${folderId}`;
  return axios.get(endpoint);
};

export const apiUpdateContentFolder = ({ folderId, name }) => {
  const endpoint = `${config.apiUrl}userapi/contents/folders/${folderId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(endpoint, {
        name,
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};
