<template>
  <div
    ref="templateContainer"
    v-if="template"
    class="template-main-container"
    :style="templateStyle"
  >
    <template v-if="!isLoading">
      <TemplateLayer v-for="widget in widgets" :key="widget.assoc_id" :widget="widget" />
    </template>
    <div v-else class="loader">
      <div class="loader-background">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateLayer from '@/components/templates/viewer/TemplateLayer.vue';
  import Loader from '@/components/common/Loader.vue';

  import {
    apiGetScreenGroupTemplateChildren,
    apiGetScreenTemplateChildren,
    apiGetTemplateChildren,
  } from '@/api/templates';
  import { formatTemplates } from '@/helpers/mixins';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'TemplateViewer',

    components: {
      TemplateLayer,
      Loader,
    },

    props: {
      template: {
        type: Object,
        default: () => null,
      },

      zoom: {
        type: Number,
        default: 1,
      },

      playlistId: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        widgets: [],
        isLoading: false,
      };
    },

    mounted() {
      this.getWidgets();
    },

    watch: {
      template(value) {
        if (value) {
          this.getWidgets();
        }
      },
    },

    computed: {
      templateStyle() {
        const template = this.template?.settings || {};

        return {
          backgroundColor: getRBGAColor(template.backgroundColor, template.opacity / 100),
          width: `100%`,
          height: `100%`,
          zoom: this.zoom,
          overflow: 'hidden',
        };
      },
    },

    methods: {
      async getWidgets() {
        try {
          const { screenId, groupId, layout } = this.$store.state.player;
          this.isLoading = true;
          this.widgets = [];

          let response;

          if (groupId) {
            response = await apiGetScreenGroupTemplateChildren({
              groupId,
              playlistId: this.playlistId,
              templateId: this.template.template_id,
              layoutId: layout?.layout_id,
            });
          } else if (screenId) {
            response = await apiGetScreenTemplateChildren(
              this.template.template_id,
              screenId,
              this.playlistId,
            );
          } else {
            response = await apiGetTemplateChildren(this.template.template_id);
          }

          const formatedWidgets = formatTemplates(response.data);

          this.widgets = formatedWidgets;
          this.isLoading = false;
        } catch (error) {
          console.log('getWidgets ~ error:', error);
          this.$toasted.global.general_error({
            message: 'Not able to load Template Preview',
          });
        }
      },
    },

    provide() {
      return {
        template: () => this.template,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .template-main-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    .loader-background {
      background-color: rgba(0, 0, 0, 0.25);

      border-radius: 10px;

      height: min(100px, 80%);
      width: min(100px, 80%);
    }
  }
</style>
