<script lang="ts">
  export default {
    name: 'EmptyState',
  };
</script>

<script setup lang="ts">
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
</script>

<template>
  <div class="empty-state-container">
    <img
      class="icon"
      src="@/assets/icon/openCardboardBox/openCardboardBox.svg"
      alt="open cardboard box"
    />
    <slot name="title">
      <BaseText variant="h6" color="#151515">Nothing to show</BaseText>
    </slot>
    <slot name="subtitle"></slot>
  </div>
</template>

<style scoped lang="scss">
  .empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px;
    width: 100%;
    height: 100%;
    background: white;
  }

  .icon {
    width: 50px;
    height: 44px;
    margin-bottom: 12px;
  }
</style>
