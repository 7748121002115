import { getRSSAppSettings } from '@/helpers/utils';

export default {
  computed: {
    templateDimensions() {
      const { width = 0, height = 0 } = this.template()?.settings || {};
      return {
        templateWidth: width,
        templateHeight: height,
        templateRatio: 1,
      };
    },

    isPlaylistsPreview() {
      return this.$route.name === 'Playlists';
    },
  },

  methods: {
    defaultStyling(widget) {
      let zoom = 1;
      const position = widget.position;
      const usePixels =
        widget.position.usePixels &&
        typeof position.xPixels === 'number' &&
        !this.isPlaylistsPreview;

      if (this.$route.meta.isPlaylistDemo && !this.$store.state.playlist.isPlaylistPreviewOn) {
        const { templateWidth, templateHeight } = this.templateDimensions;
        const maxValue = templateWidth > templateHeight ? templateWidth : templateHeight;
        zoom = 320 / maxValue;
      }

      return {
        ...widget,
        left: usePixels ? `${position.xPixels}px` : `${position.x}%`,
        top: usePixels ? `${position.yPixels}px` : `${position.y}%`,
        height: usePixels ? `${position.heightPixels}px` : `${position.height}%`,
        width: usePixels ? `${position.widthPixels}px` : `${position.width}%`,
        borderRadius: `${widget.object.borderRadius || 0}px`,
        zIndex: 100 + position.zIndex,
        zoom,
      };
    },

    getRSSAppSettings,
  },

  inject: ['template'],
};
